import React, { useEffect, useContext, useState, useRef } from 'react';
import { arrayOf, bool, func, node, oneOf, shape, string } from 'prop-types';
import classnames from 'classnames';
import { Button } from '@andes/button';
import { TextField } from '@andes/textfield';
import { Checkbox } from '@andes/checkbox';
import { Form } from '@andes/form';
import { AndesProvider } from '@andes/context';
import { trackEvent, trackEventWithCustomFields } from '../../lib/tracking';
import StaticPropsContext from '../context/static-props';
import useTrackView from '../../hooks/use-track-view';
import BaseModal from '../modals/base-modal';
import QuestionsQuickAccessMobile from './questions-quick-access.mobile';
import QuestionsQuickAccessDesktop from './questions-quick-access.desktop';
import QuestionsMessage from './questions-message';
import onIframeModalResize from '../../lib/on-iframe-modal-resize';
import Media from '../media';
import QuestionsMessageLocation from './questions-message-location';
import PreventionFraudMessage from './questions-message-fraud';
import { constants } from '../../utils/constants';
import { generateKeyToComponent } from '../../utils/validators';

const DEFAULT_DELAY_TIME = 3000;
const { devices } = constants;
const { MOBILE } = devices;

const namespace = 'ui-pdp-questions';

const buildMotorsQuestionButton = actions =>
  actions.map(action => (
    <Button
      key={generateKeyToComponent(action)}
      className={`${namespace}__button`}
      hierarchy="loud"
      href={action.target}
    >
      {action.label.text}
    </Button>
  ));

// eslint-disable-next-line complexity
const Questions = ({
  className,
  children,
  disabled,
  form,
  id,
  messages,
  title,
  show_track,
  onCreateQuestion,
  url,
  stockModal: stockModalProps,
  track,
  question_message,
  quick_access,
  condition,
  message_location,
  message_prevention_fraud,
  focus,
  onRequestFocus,
  enableRedirectAction,
  onScrollComponent,
  closeModalLabel,
  showSnackbar,
}) => {
  const { deviceType, flashMessage } = useContext(StaticPropsContext);
  const [isFocus, setIsFocus] = useState(focus);
  const creditCheckbox = typeof form?.leads_credit_checkbox?.text === 'string' ? false : null;
  const [creditsInterestChecked, setCreditsInterestChecked] = useState(creditCheckbox);
  const [shouldShowSnackbar, setShouldShowSnackbar] = useState(messages && messages.text);
  const showTrackType = show_track?.melidata_event?.type;
  // -------------------------------------
  // ----- BEGIN GUEST QUESTON LOGIC -----
  // -------------------------------------
  const [activeFlashMessage, setActiveFlashMessage] = useState(flashMessage);
  let stockModal = flashMessage && flashMessage.stock_modal;

  if (stockModalProps) {
    stockModal = stockModalProps;
  }

  if (activeFlashMessage) {
    if (flashMessage.stock_track) {
      trackEvent(flashMessage.stock_track);
    }

    if (!stockModal) {
      const extraFields = flashMessage.extra_post_event_data || {};
      extraFields.failed = !flashMessage.success;
      trackEventWithCustomFields(track, extraFields);
    }

    setActiveFlashMessage(null);
  }
  // ---------------------------------
  // ----- END GUEST QUESTON LOGIC -----
  // -----------------------------------

  const [modalVisible, setModalVisible] = useState(true);
  const [value, setValue] = useState((form && form.default_question_text) || '');
  const inputRef = useRef(null);

  const onInputChange = e => {
    setValue(e.target.value);
  };

  const onInputFocus = () => {
    trackEvent(form.focus_track);
  };

  const creditsInterestToggle = e => {
    setCreditsInterestChecked(e.target.checked);
  };

  const handleOnSubmit = e => {
    e.preventDefault();
    if (value.trim() !== '') {
      onCreateQuestion({
        itemId: form.confirm.item_id,
        text: value,
        track,
        checkbox: creditsInterestChecked,
      });
      setValue('');
    } else {
      inputRef.current.focus();
    }
  };

  if (shouldShowSnackbar) {
    showSnackbar({
      message: messages.text,
      type: messages.type,
      delay: DEFAULT_DELAY_TIME,
    });
    setShouldShowSnackbar(false);
  }

  useEffect(() => {
    setShouldShowSnackbar(messages && messages.text);
  }, [messages]);

  useEffect(
    () => () => {
      if (stockModal) {
        setModalVisible(true);
      }
    },
    [stockModal],
  );

  // Thresholds are defined to know when te component is usable
  const refQuestions = useTrackView(show_track, { threshold: 0.2 }, showTrackType && showTrackType.toLowerCase());

  useEffect(() => {
    if (focus) {
      refQuestions.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        setIsFocus(true);
      }, 500);
    }
  }, [focus, refQuestions]);

  return (
    <div ref={refQuestions} className={classnames(namespace, className)} id={id}>
      {stockModal && (
        <BaseModal
          data-testid="stock-modal"
          key={`${namespace}__stock-modal-${Math.random()}`}
          id={`${namespace}__stock-modal`}
          isOpen={modalVisible}
          onClose={() => setModalVisible(false)}
          closable
          closeModalLabel={closeModalLabel}
          autoHeight={false}
          title={stockModal.title.text}
          type={deviceType === 'mobile' ? 'card' : 'large'}
          maxWidth={500}
        >
          <p data-testid="stock-modal-text" className={`${namespace}__stock-modal__text`}>
            {stockModal.content.text}
          </p>
          <Button
            data-testid="stock-modal-confirm"
            className={`${namespace}__stock-modal__button`}
            onClick={e => {
              e.preventDefault();
              setModalVisible(false);
            }}
            hierarchy="loud"
          >
            {stockModal.confirm.label.text}
          </Button>
        </BaseModal>
      )}
      {title && <h2 className={`${namespace}__title`}>{title.text}</h2>}
      {quick_access ? (
        <>
          {deviceType === 'mobile' ? (
            <QuestionsQuickAccessMobile
              {...quick_access}
              onScrollComponent={onScrollComponent}
              onIframeMessage={onIframeModalResize(
                'vip:modal-content:loaded',
                'height',
                '.ui-pdp-iframe-modal',
                '.andes-modal__header',
              )}
            />
          ) : (
            <QuestionsQuickAccessDesktop
              {...quick_access}
              onScrollComponent={onScrollComponent}
              onIframeMessage={onIframeModalResize(
                'vip:modal-content:loaded',
                'height',
                '.ui-pdp-iframe-modal',
                '.andes-modal__header',
                'width',
              )}
            />
          )}
        </>
      ) : null}
      {question_message && <QuestionsMessage {...question_message} />}
      {message_location && <QuestionsMessageLocation {...message_location} />}
      {message_prevention_fraud && <PreventionFraudMessage {...message_prevention_fraud} />}
      {form && (
        <>
          {form.title && (
            <h3 aria-hidden="true" className={`${namespace}__subtitle--question`}>
              {form.title.text}
            </h3>
          )}
          <AndesProvider>
            <Form className={`${namespace}__form`} action={url} method="POST" onSubmit={handleOnSubmit}>
              {form.confirm && <input type="hidden" name="itemId" value={form.confirm.item_id} />}
              <div
                className={classnames(`${namespace}__content`, {
                  [`${namespace}__checkbox-credits-interest-content`]: form.leads_credit_checkbox,
                })}
              >
                {form.title && (
                  <label className="ui-pdp--hide" htmlFor="question">
                    {form.title.text}
                  </label>
                )}
                <TextField
                  id="question"
                  name="question"
                  data-testid="questions-input"
                  className={`${namespace}__input`}
                  placeholder={form.placeholder.text}
                  setField={innerRef => {
                    inputRef.current = innerRef;
                  }}
                  autoComplete="off"
                  value={value}
                  message={form.subtitles && form.subtitles.map(s => s.text).join('')}
                  onChange={onInputChange}
                  onFocus={onInputFocus}
                  messageShow
                  messageFixed
                  maxLength={2000}
                  multiline
                  focus={isFocus}
                  onBlur={() => {
                    if (onRequestFocus) {
                      setIsFocus(false);
                      onRequestFocus({ focus: false });
                    }
                  }}
                />
                {form.leads_credit_checkbox && (
                  <div className={`${namespace}__checkbox-credits-interest__wrapper`}>
                    <Checkbox
                      data-testid="checkbox-leads"
                      label={form.leads_credit_checkbox.text}
                      className={`${namespace}__checkbox-credits-interest`}
                      onChange={creditsInterestToggle}
                      checked={creditsInterestChecked}
                    />
                  </div>
                )}
                {form.confirm && (
                  <Button
                    data-testid="questions-button"
                    className={`${namespace}__button`}
                    hierarchy="loud"
                    type="submit"
                    disabled={disabled}
                    fullWidth={deviceType === MOBILE}
                  >
                    {form.confirm.label.text}
                  </Button>
                )}
              </div>
              {enableRedirectAction && form.additional_actions && buildMotorsQuestionButton(form.additional_actions)}
              {condition && <Media className={`${namespace}__conditions`} title={condition} />}
            </Form>
          </AndesProvider>
        </>
      )}
      {children}
    </div>
  );
};

Questions.propTypes = {
  className: string,
  disabled: bool,
  form: shape({
    title: shape({ text: string.isRequired }),
    confirm: shape({
      label: shape({
        text: string.isRequired,
      }),
      target: string.isRequired,
      item_id: string.isRequired,
    }).isRequired,
    focus_track: shape({}),
    default_question_text: string,
    subtitles: arrayOf(shape({ text: string.isRequired })).isRequired,
    placeholder: shape({ text: string.isRequired }).isRequired,
  }),
  id: string.isRequired,
  messages: shape({
    text: string,
    type: oneOf(['success', 'error']),
  }),
  children: node,
  title: shape({ text: string.isRequired }),
  show_track: shape({}),
  url: string.isRequired,
  stockModal: shape({}),
  quick_access: shape({
    actions: arrayOf(
      shape({
        label: shape({
          text: string.isRequired,
        }),
        target: string.isRequired,
      }),
    ),
    title: shape({ text: string.isRequired }).isRequired,
  }),
  question_message: shape({
    title: shape({ text: string.isRequired }).isRequired,
    subtitle: shape({ text: string.isRequired }).isRequired,
  }),
  track: shape({}).isRequired,
  onCreateQuestion: func.isRequired,
  condition: shape({
    text: string.isRequired,
    values: shape(),
  }),
  message_prevention_fraud: shape({
    hierarchy: string,
    icon: shape({ id: string, color: string }),
    message_type: string,
    title: shape({ text: string }),
    type: string,
  }),
  message_location: shape({
    hierarchy: string,
    icon: shape({ id: string, color: string }),
    message_type: string,
    title: shape({ text: string }),
    type: string,
  }),
  focus: bool,
  onRequestFocus: func,
  enableRedirectAction: bool,
  onScrollComponent: func,
  closeModalLabel: string,
  showSnackbar: func,
};

Questions.defaultProps = {
  className: null,
  closeModalLabel: null,
  children: null,
  condition: null,
  messages: null,
  show_track: null,
  stockModal: null,
  quick_access: null,
  question_message: null,
  message_location: null,
  message_prevention_fraud: null,
  title: null,
  disabled: false,
  focus: false,
  onRequestFocus: null,
  form: null,
  enableRedirectAction: false,
  onScrollComponent: null,
  showSnackbar: null,
};

export default Questions;
