import React from 'react';
import { number, node, string, shape } from 'prop-types';
import get from 'lodash/get';
import trackNewrelic from '../services/client/new-relic.client';
import historyService from '../services/history';
import SnackBarError from '../components/snackbar';
import { messagesErrorScreen } from '../lib/get-message-error';
import { feStatsdHelper } from '../utils/frontendStatsdHelper';
import { UNKNOWN_VALUE } from '../services/frontend-statsd/config/allowed-tags';

import {
  VPP_BOUNDARY_DATADOG_KEY_CASES,
  VPP_BOUNDARY_DATADOG_KEY_PREFIX,
} from '../services/frontend-statsd/config/allowed-keys';

const history = historyService.getHistory();

// Datadog config - ERROR BOUNDARY
const statsdConfig = {
  keyPrefix: VPP_BOUNDARY_DATADOG_KEY_PREFIX,
};

class GlobalErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    if (typeof window === 'undefined' && process?.env?.NODE_ENV === 'test') {
      return { hasError: false };
    }
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.error && get(nextProps, 'error.response.status') !== get(prevState, 'error.response.status')) {
      return { hasError: true, error: nextProps.error.response };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidMount() {
    this.unlisten = history.listen((location, action) => {
      /* if current action is POP we reload the page */
      if (action === 'POP') {
        /*
         * if previous stored pathname/search is not equal to the current pathname/search trigger history change,
         * search may be different if quantity changes
         */
        if (this.location.pathname !== location.pathname || this.location.search !== location.search) {
          /*
           * ideally we should get id from state, if isn't available in the state fallback to pathname
           * e.g /p/MLA9652755?quantity=1, /p/MLA9652755/s?quantity=1
           * for now we reload the page in a future we should be able to cancel the current action's request
           * and trigger a new action without reloading the page
           * */
          window.location.reload();
        }
      }
      this.location = location;
    });
    /* save initial location */
    this.location = history.location;
  }

  componentDidCatch(error, info) {
    // log error to new relic if it's an ui error or diff from 404 || 500
    trackNewrelic({ error, info });

    const { app, deviceType } = this.props;
    const metricData = {
      referer_app: app || UNKNOWN_VALUE,
      device_type: deviceType || UNKNOWN_VALUE,
      catch_context: 'client_side',
      origin: 'catch',
    };

    feStatsdHelper({
      key: VPP_BOUNDARY_DATADOG_KEY_CASES.ERROR_BOUNDARY_GLOBAL,
      statsdConfig,
      additionalTags: metricData,
      shouldLogMessage: true,
      additionalInfo: { error: error.stack },
      messageTitle: 'Vpp-ErrorBoundary - GlobalBoundary',
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    const { children, error } = this.props;
    const { hasError } = this.state;
    if (error) {
      const { app, deviceType, siteId } = this.props;
      const { title, description } = messagesErrorScreen(siteId);

      const metricData = {
        referer_app: app,
        device_type: deviceType,
        catch_context: 'client_side',
        origin: 'state',
      };
      feStatsdHelper({
        key: VPP_BOUNDARY_DATADOG_KEY_CASES.ERROR_BOUNDARY_GLOBAL,
        statsdConfig,
        additionalTags: metricData,
        shouldLogMessage: true,
        additionalInfo: { error },
        messageTitle: 'Vpp-ErrorBoundary - GlobalBoundary',
      });
      return (
        <>
          {children}
          <SnackBarError show message={`${title} - ${description}`} />
        </>
      );
    }
    if (hasError) {
      const { siteId } = this.props;
      const { title, description } = messagesErrorScreen(siteId);
      return <SnackBarError show message={`${title} - ${description}`} />;
    }
    return children;
  }
}

GlobalErrorBoundary.propTypes = {
  children: node.isRequired,
  error: shape({
    status: number,
    statusText: string,
  }),
  app: string,
  deviceType: string,
  siteId: string,
};

GlobalErrorBoundary.defaultProps = {
  error: null,
  siteId: null,
  app: null,
  deviceType: null,
};

export default GlobalErrorBoundary;
