import React from 'react';
import { bool, node, shape, string } from 'prop-types';
import serialize from 'serialize-javascript';
import MeliGA from 'nordic/analytics/meli-ga';
import MelidataTrack from 'nordic/melidata/melidata-track';
import { Head } from 'nordic/head';
import { Script } from 'nordic/script';
import { Style } from 'nordic/style';
import get from 'lodash/get';
import classNames from 'classnames';
import { Qualtrics } from 'qualtrics-wrapper';
import { LazyHydrate as Hydrator, loadable } from 'nordic/lazy';
import RedirectApp from 'redirect-app-web';
import Schema from '../../../components/schema';
import { UserProvider } from '../../../components/context/user';
import { StaticPropsProvider } from '../../../components/context/static-props';
import { StoreProvider } from '../../../store/context';
import vipCoreReducer from '../../../reducers/vip/core';
import Metadata from '../../../components/metadata';
import FlashMessage from '../../../components/flash-message';
import GoogleTagManager from '../../../components/gtm';
import Hotjar from '../../../components/hotjar';
import useMShopsTrackingInfo from '../../../hooks/use-mshops-tracking-info';
import { constants } from '../../../utils/constants';
import TrackPageVariation from '../features/tracking';
import { getEmbeddedData } from '../../helpers/getEmbeddedData';
import { AndesProvider } from '@andes/context';
import getSanitizedTitle from '../../../utils/getSanitizedTitle';

const WrapperEshops = loadable(({ show }) => (show ? import('../../../components/eshops') : null));

// eslint-disable-next-line complexity
const View = ({
  children,
  initialState,
  country,
  csrfToken,
  currentUser,
  deviceType,
  deviceDpr,
  isLegacyBrowser,
  lowEnd,
  translations,
  site,
  siteId,
  flashMessage,
  vertical,
  gtm,
  isIE,
  shopModel,
  imagePrefix,
  openInApp,
  page,
  cpCookie,
  coachMarkCookie,
  isGuestMS,
  nonce,
}) => {
  const { layout } = initialState;
  const { QUALTRICS_ID } = constants;
  const staticProps = {
    layout,
    flashMessage,
    site,
    siteId,
    lowEnd,
    deviceType,
    deviceDpr,
    country,
    isIE,
    vertical,
    page,
    cpCookie,
    coachMarkCookie,
    isGuestMS,
  };

  const serializeProps = {
    translations,
    initialState,
    site,
    siteId,
    lowEnd,
    deviceType,
    deviceDpr,
    country,
    currentUser,
    csrfToken,
    flashMessage,
    gtm,
    isIE,
    shopModel,
    imagePrefix,
    openInApp,
    page,
    cpCookie,
    coachMarkCookie,
  };

  useMShopsTrackingInfo(get(initialState, 'shopsDefaultTrackingData'));

  const isTopBrand =
    (deviceType === 'desktop' || deviceType === 'tablet') &&
    initialState?.components?.fixed?.header_top_brand_vip &&
    initialState?.components?.fixed?.header_top_brand_vip?.state === 'VISIBLE';
  const isOfficialStore =
    (deviceType === 'desktop' || deviceType === 'tablet') &&
    (!!initialState?.components?.fixed?.header_store || isTopBrand);
  const isCPG = !!initialState.components.header_cpg;
  const hasEshopData =
    initialState?.storefront?.appearance?.hidden === false &&
    initialState?.storefront?.appearance?.children?.length > 0;

  const countryAs = country.locale.replace('_', '-') || country.locale;
  const hasClips = !!initialState?.components?.gallery?.clips?.shorts?.length;

  const hasEnhancedVideos = initialState?.components?.enhanced_content?.media_content?.some(
    elem => elem.type.toLowerCase() === 'video',
  );
  const hasTrafficSniffer = initialState?.components?.traffic_sniffer?.state?.toLowerCase() === 'visible';

  const { securityScript } = initialState || {};
  const pictures = { ...get(initialState, 'components.gallery.pictures') };
  const sanitizedTitle = getSanitizedTitle(pictures);
  const metadata = get(initialState, 'metadata');
  const schema = get(initialState, 'schema');

  const shopsTheme = get(initialState, 'shops_validator?.appearance.properties.theme', null);

  const containerClasses = classNames([
    'ui-vip-core',
    {
      [`ui-vip-core-mshops-container--${shopsTheme}`]: shopsTheme,
      'ui-vip-core--official-store': isOfficialStore,
      'ui-vip-core--cpg': isCPG,
    },
  ]);

  return (
    <>
      {openInApp.shouldRedirect && <RedirectApp {...openInApp} />}
      <Hydrator whenIdle>
        <Schema schemaData={schema} sanitizedTitle={sanitizedTitle} />
      </Hydrator>
      <Head>
        <meta name="csrf-token" content={csrfToken} />
        {(hasClips || hasEnhancedVideos) && (
          <link
            href="https://http2.mlstatic.com/frontend-assets/shorts-nordic-viewer/videojs.4ff5eea5e00eaf6f87b4.css"
            rel="stylesheet"
          />
        )}
        {(hasClips || hasEnhancedVideos) && (
          <Script src="https://http2.mlstatic.com/frontend-assets/shorts-nordic-viewer/videojs.a23f701605564af23722.js" />
        )}
      </Head>
      {flashMessage && flashMessage.text && <FlashMessage {...flashMessage} />}
      <div className={containerClasses}>
        <MeliGA
          section={get(initialState, 'track.analytics_event.section')}
          page={get(initialState, 'track.analytics_event.page')}
          business={get(initialState, 'track.analytics_event.custom_dimensions.business')}
          pageVertical={get(initialState, 'track.analytics_event.custom_dimensions.pageVertical')}
          pathFromRoot={get(initialState, 'track.analytics_event.custom_dimensions.pathFromRoot')}
          dimensions={get(initialState, 'track.analytics_event.custom_dimensions.customDimensions')}
        />

        <MelidataTrack
          path={get(initialState, 'track.melidata_event.path')}
          event_data={get(initialState, 'track.melidata_event.event_data')}
          experiments={get(initialState, 'track.melidata_event.experiments', {})}
        />

        <Style href={`vip.${vertical}.${deviceType}.css`} critical={deviceType === 'mobile'} />
        {lowEnd && <Style href="vip.lowend.css" critical={deviceType === 'mobile'} />}
        <Script inline on="now">
          {`
            window.requestIdleCallback =
              window.requestIdleCallback ||
              function(cb) {
                  var start = Date.now();
                  return setTimeout(function() {
                      cb({
                          didTimeout: false,
                          timeRemaining: function() {
                              return Math.max(0, 50 - (Date.now() - start));
                          },
                      });
                  }, 1);
              };

            window.cancelIdleCallback =
              window.cancelIdleCallback ||
              function(id) {
                  clearTimeout(id);
              };
            window.__PRELOADED_STATE__ = ${serialize(serializeProps, { isJSON: true })};
        `}
        </Script>

        {isLegacyBrowser && <Script src="polyfill.js" />}
        {isIE && <Script src="polyfills-ie.js" />}
        <Script src={`vip.${vertical}.${deviceType}.js`} />
        <Script src="https://http2.mlstatic.com/frontend-assets/frontend-viewability/viewability-script.1.1.0.js">
          {`window.viewability.setNonceForCSP('${nonce}')`}
        </Script>
        {site === 'PI' && (
          <Script
            src="https://3e31081d8fc9.edge.sdk.awswaf.com/3e31081d8fc9/4a188c736202/challenge.js"
            crossOrigin={null}
            defer
          />
        )}
        {site !== 'PI' && hasTrafficSniffer && (
          <Script
            src="https://3e31081d8fc9.edge.sdk.awswaf.com/3e31081d8fc9/33944383e41d/challenge.js"
            crossOrigin={null}
            defer
          />
        )}
        <Script src="errorhandler.js" priority={2} on="now" inline />
        <Script priority={1} on="now">
          {
            // Registrar la app para el tag de error handler
            'window.__eh_app_tag__ = "vip";'
          }
        </Script>
        {securityScript && <Script src={securityScript} crossOrigin="anonymous" defer />}
        <Qualtrics interceptId={QUALTRICS_ID} embeddedData={getEmbeddedData(initialState)} />
        <Hotjar {...get(initialState, 'track.hotjar_event')} />
        <Metadata {...metadata} sanitizedTitle={sanitizedTitle} />
        <GoogleTagManager {...gtm} dataLayer={get(initialState, 'track.gtm_event')} />

        <AndesProvider locale={countryAs} csrfToken={csrfToken}>
          <UserProvider value={currentUser}>
            <StaticPropsProvider value={staticProps}>
              <StoreProvider siteId={siteId} defaultState={initialState} reducer={vipCoreReducer}>
                {hasEshopData && (
                  <WrapperEshops show={hasEshopData} {...initialState.storefront.appearance} siteId={siteId} />
                )}
                {children}
                <Hydrator whenIdle>
                  <TrackPageVariation />
                </Hydrator>
              </StoreProvider>
            </StaticPropsProvider>
          </UserProvider>
        </AndesProvider>
      </div>
    </>
  );
};

View.propTypes = {
  baseURL: string,
  children: node.isRequired,
  country: shape({
    decimal_separator: string.isRequired,
    thousands_separator: string.isRequired,
    locale: string.isRequired,
  }).isRequired,
  csrfToken: string.isRequired,
  currentUser: shape({
    nickname: string.isRequired,
  }),
  gtm: shape(),
  deviceType: string.isRequired,
  deviceDpr: string,
  flashMessage: string,
  isLegacyBrowser: bool,
  initialState: shape().isRequired,
  lowEnd: bool.isRequired,
  site: string.isRequired,
  siteId: string.isRequired,
  translations: shape(),
  shopModel: shape(),
  vertical: string,
  isIE: bool,
  imagePrefix: string,
  openInApp: shape({
    shouldRedirect: bool,
  }).isRequired,
  page: string,
  cpCookie: string,
  coachMarkCookie: string,
  isGuestMS: string,
  nonce: string,
};

View.defaultProps = {
  currentUser: null,
  flashMessage: null,
  gtm: null,
  isLegacyBrowser: true,
  translations: {},
  shopModel: {},
  vertical: 'core',
  baseURL: '/',
  isIE: false,
  imagePrefix: null,
};

export default View;
